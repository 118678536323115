<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" :style="canClick ? 'cursor: pointer;' : 'cursor: default;'">

            <v-card-text v-if="$vuetify.breakpoint.width > 450" class="card-state pa-0 pl-2">
                <span v-if="false" class="caption red--text">SelectorCard {{ item }}</span>
                <v-row justify="center" align="center" class="py-1" no-gutters>
                    <v-col cols="1"><v-icon small class="gold--text pr-1">mdi-gold</v-icon></v-col>
                    <v-col cols="2" align="center"><span class="px-2">{{item.date}}</span></v-col>
                    <v-col cols="3" align="center"><span class="px-2 gold white--text rounded py-0">{{item.transaction}}</span></v-col>
                    <v-col cols="2" align="center"><span class="px-2">{{item.type}}</span></v-col>
                    <v-col cols="2" align="center"><span class="px-2">{{item.payment}}</span></v-col>
                    <v-col cols="2" align="center"><span class="px-2">{{item.goldAmount}}</span></v-col>
                </v-row>
            </v-card-text>

            <v-card-text v-else class="card-state pa-0 pl-2">
                <span v-if="false" class="caption red--text">SelectorCard {{ item }}</span>
                <v-row class="py-1" no-gutters>
                    <v-col cols="1"><v-icon small class="gold--text pr-1">mdi-gold</v-icon></v-col>
                    <v-col cols="4" align="center"><span class="px-2">{{item.date}}</span></v-col>
                    <v-col cols="6" align="center"><span class="px-2 gold white--text rounded py-0">{{item.transaction}}</span></v-col>
                </v-row>
                <v-row class="py-1" no-gutters>
                    <v-col cols="1"></v-col>
                    <v-col cols="4" align="center"><span class="px-2">{{item.type}}</span></v-col>
                    <v-col cols="4" align="center"><span class="px-2">{{item.payment}}</span></v-col>
                    <v-col cols="3" align="center"><span class="px-2">{{item.goldAmount}}</span></v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
import Permissions from "@/services/auth/permissions";
import AuthMixin from "@/services/auth/auth_mixin";

export default {
    name: "AgreementGoldPurchaseCard",
    mixins: [AuthMixin],
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    computed: {
        canClick() {
            return this.checkPermission(Permissions.ADMINISTRATOR)
        }
    }
}
</script>