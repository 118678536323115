<template>
    <section class="py-4" v-if="document_ready">
        <v-container fluid>
            <v-row>
                <v-col class="pa-0" cols="12" v-for="goldPurchase in getGoldPurchases" :key="goldPurchase.id">
                    <AgreementGoldPurchaseCard :item="goldPurchase" />
                </v-col>
            </v-row>
        </v-container>

        <!--FOOTER-->
        <v-container fluid :class="this.$vuetify.theme.dark ? 'black' : 'grey lighten-2'"
                     style="position: fixed; bottom: 0; width: 100%; text-align: center;">
            <v-btn v-if="card_item.raw_data.product_type === 'productgoldt3'" color="gold" dark small @click="downloadInvoice">
                <v-icon left>mdi-cloud-download</v-icon>
                <span>{{ $t('products_packages.dialog.download_invoice') }}</span>
            </v-btn>
        </v-container>
    </section>
</template>

<script>
import {downloadAgreementInvoice} from "@/api/agreements";
import AgreementGoldPurchaseCard from "@/components/cards/AgreementGoldPurchaseCard";

export default {
    name: "MyAgreementDialog",
    components: {
        AgreementGoldPurchaseCard
    },
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },

    data: () => ({
        document_ready: false
    }),

    watch: {
        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    methods: {
        downloadInvoice() {
            this.$root.$emit('overlay', true);
            downloadAgreementInvoice(this.id).then(() => {
                this.$root.$emit('overlay', false);
            }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        }
    },

    computed: {
        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.card_item.product + ' • ' + this.id
                }
            } else {
                return this.edit;
            }
        },

        getGoldPurchases() {
            return this.$store.getters.getGoldPurchases.items;
        }
    },

    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    },

    mounted() {
        this.$store.dispatch("getGoldPurchases", this.id)
            .then(() => {
                this.document_ready = true;
            }).catch(()=>{
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })

    },
    destroyed() {
        this.$store.dispatch('clearGoldPurchases');
    }

}
</script>